// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$espace-partenaire-primary: mat.define-palette(mat.$orange-palette);
$espace-partenaire-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$espace-partenaire-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$espace-partenaire-theme: mat.define-light-theme(
  (
    color: (
      primary: $espace-partenaire-primary,
      accent: $espace-partenaire-accent,
      warn: $espace-partenaire-warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($espace-partenaire-theme);

/* You can add global styles to this file, and also import other style files */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer components {
  .btn-primary {
    @apply py-3 px-6 bg-miel text-white rounded-xl hover:bg-miel-20 focus:outline-none focus:ring focus:ring-miel-20 focus:ring-opacity-50 active:bg-miel-30;
  }
  .btn-primary-outline {
    @apply py-3 px-6 bg-opacity-0 text-miel rounded-xl hover:text-miel-20 hover:border-miel-20 border border-miel focus:outline-none focus:ring focus:ring-miel-20 focus:ring-opacity-50 active:text-miel-30 active:border-miel-30;
  }
  .title {
    @apply font-brown-bold text-2xl font-bold;
  }
  .title-2 {
    @apply font-brown-regular text-2xl font-medium;
  }
  .section-primary {
    @apply bg-white px-6 py-4;
  }
  .section-secondary {
    @apply bg-miel bg-opacity-10 px-5 py-3 border border-beige-20 rounded-lg;
  }
  .data-list-primary {
    @apply grid grid-cols-2 md:grid-cols-4 xl:grid-cols-6 gap-2 items-center;
    dd {
      @apply text-sm;
    }
  }
  .checkbox {
    @apply appearance-none w-4 h-4 self-center rounded-sm  border-2 border-beige-20 bg-white checked:bg-[url('/assets/icons/checkmark.svg')] checked:border-none outline-none focus:ring focus:ring-miel-20 focus:ring-opacity-50;
  }
  .input-primary {
    @apply bg-beige-10 border border-beige-20 px-3 py-2 rounded placeholder-beige-30 focus:border-beige-30 outline-none;
  }
  .date-picker {
    @apply relative;
    input {
      @apply input-primary;
    }
    mat-datepicker-toggle {
      @apply absolute right-1 top-1;
      .mat-mdc-icon-button.mat-mdc-button-base {
        @apply p-0 w-8 h-8;
      }
      .mat-icon {
        @apply scale-90;
      }
    }
  }
  .select-primary {
    :required {
      @apply text-beige-30;
    }
    @apply input-primary;
    option {
      @apply bg-beige-10 text-black;
    }
  }

  .table-primary {
    .mat-mdc-header-row {
      @apply bg-beige-10;
    }
    .mat-mdc-header-cell {
      @apply bg-beige-10 border-b border-beige-20;
    }
    .mat-mdc-header-cell:first-child {
      @apply rounded-tl-lg;
    }
    .mat-mdc-header-cell:last-child {
      @apply rounded-tr-lg;
    }
    .mat-mdc-row {
      @apply border-b border-beige-20;
    }
    .mat-mdc-cell {
      @apply border-b-0 border-beige-20 rounded-t-lg;
    }
  }

  .paginator {
    .mat-mdc-paginator-icon {
      @apply fill-miel;
    }

    .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
      @apply fill-beige-20;
    }
  }

  .button-icon-brown {
    @apply rounded-full bg-beige-10 h-6 w-6 text-beige-40;
  }
}

@font-face {
  font-family: 'CenturyGothic';
  src: url('assets/fonts/centurygothic.ttf');
}

@font-face {
  font-family: 'CenturyGothicBold';
  src: url('assets/fonts/centurygothic_bold.ttf');
}

@font-face {
  font-family: 'Brown';
  src: url('assets/fonts/brown_regular.ttf');
}

@font-face {
  font-family: 'BrownBold';
  src: url('assets/fonts/brown_bold.ttf');
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  @apply font-brown-regular bg-beige-10;
}
